import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #ffffff;
  border-radius: 3px;
  padding: 28px 20px 45px;
  margin-top: -80px;
  text-align: center;

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #a57d73;
    background-size: cover;
    background-position: center;
    margin: 0 auto 20px;
    display: block;
  }
  
  h2 {
    font: italic bold 30px 'Times new roman';
    color: #000000;
    margin-bottom: 17px;
  }

  a.site {
    color: #696969;
    font-size: 16px;
  }

  .location {
    color: #696969;
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      margin-right: 7px;
      background: url('/images/pin.svg') no-repeat center;
      background-size: contain;
      width: 16px;
      height: 22px;
    }

    span {
      text-transform: uppercase;
    }
  }

  .social-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;

    a {
      margin: 0 7px;
      i {
        height: 46px;
        width: 46px;
        display: flex;
      }
    }
  }

  .profile-description {
    p {
      font-size: 14px;
      line-height: 26px;
      color: #696969;
      margin-bottom: 15px;
      text-align: left;
    }
  }

  .btn-edit {
    display: flex;
    margin: 0 auto 23px;
    width: 260px;
    height: 50px;

    span {
      justify-content: flex-start;
      font-size: 14px;
    }
  }

  @media (min-width: 767px) {
    margin-top: -85px;
    
    .btn-edit {
      margin: 0 auto 28px;
      width: 260px;
    }
  }

  @media (min-width: 1200px) {
    margin-top: -90px;

    .btn-edit {
      margin: 0 auto 30px;
    }
  }
`;

const PhoneIcon = styled.i`
  background: url(/images/profile-phone.svg) no-repeat center;
  background-size: contain;
  &:hover {
    opacity: .6;
  }
`

const FacebookIcon = styled.i`
  background: url(/images/profile-fb.svg) no-repeat center;
  background-size: contain;
  &:hover {
    opacity: .6;
  }
`
const InstagramIcon = styled.i`
  background: url(/images/profile-insta.svg) no-repeat center;
  background-size: contain;
  &:hover {
    opacity: .6;
  }
`
const PinterestIcon = styled.i`
  background: url(/images/profile-pinterest.svg) no-repeat center;
  background-size: contain;
  &:hover {
    opacity: .6;
  }
`
const LinkedinIcon = styled.i`
  background: url(/images/profile-youtube.svg) no-repeat center;
  background-size: contain;
  &:hover {
    opacity: .6;
  }
`

export default function SidebarProjects({ contentData }) {
  return (
    <Container>
      {contentData.company_avatar && (
        <span class="avatar" style={{"backgroundImage": `url(${contentData.company_avatar})`}}></span>
      )}

      <h2>{contentData.company_name}</h2>
      <a href={ `mailto: ${contentData.company_email}` } className="site">{contentData.company_email}</a>

      <span className="location">
        <i></i>
        {contentData.city}, {" "} <span>{contentData.uf}</span>
      </span>

      <div className="social-profile">
        {contentData.company_phone && (
          <a href={`tel:${contentData.company_phone}`} target="_blank" rel="noreferrer">
            <PhoneIcon />
          </a>
        )}
        {contentData.company_facebook && (
          <a href={contentData.company_facebook} target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
        )}
        {contentData.company_instagram && (
          <a href={contentData.company_instagram} target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
        )}
        {contentData.company_pinterest && (
          <a href={contentData.company_pinterest} target="_blank" rel="noreferrer">
            <PinterestIcon />
          </a>
        )}
        {contentData.company_linkedin && (
          <a href={contentData.company_linkedin} target="_blank" rel="noreferrer">
            <LinkedinIcon />
          </a>
        )}
      </div>

      <div className="profile-description">
        <p>{contentData.company_about}</p>
      </div>
    </Container>
  )
}