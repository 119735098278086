import React from 'react';
import styled from 'styled-components';

import ProjectCard from "../../components/project-card";

const Container = styled.div`

  h3.not-found {
    font: italic bold 30px 'Times new roman';
    color: #000000;
    margin-top: 17px;
    text-align: center;
  }

  .projects-list {
    padding-top: 35px;

    li {
      margin-bottom: 35px;
    }
  }

  .tabs-projects {
    padding-top: 35px;

    .tabs-header {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
      margin: 0 -20px 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > div {
        cursor: pointer;
        font-size: 14px;
        color: #40403f;
        font-weight: 600;
        letter-spacing: 0.32px;
        padding: 9px 14px;
        border-right: 1px solid #ffffff;
        text-align: center;
        position: relative;

        align-self: stretch;
        display: flex;
        align-items: center;

        &:last-child {
          border-right: 0;
        }

        &.active::before {
          content: "";
          display: block;
          position: absolute;
          bottom: -1px;
          width: 100%;
          left: 0;
          border-bottom: 4px solid #f1a857;
          z-index: 1;
        }
      }
    }
    .tabs-content {
      padding-bottom: 0;
    }
  }

  @media (min-width: 767px) {
    .tabs-projects {
      padding-top: 0;

      .tabs-header {
        border-top: 0;
        border-bottom: 1px solid #ffffff;
        margin: 0 -16px 35px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -1px;
          height: 1px;
          background: #ffffff;
          width: calc(100% + 3000px);
          left: 0;
        }

        & > div {
          flex: 1;
          justify-content: center;
          padding: 13px 0px 11px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .projects-list {
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 28px;

      li {
        margin-bottom: 0;
      }
    }

    .tabs-projects {
      .tabs-header {
        margin: 0 -16px 42px;

        & > div {
          flex: auto;
          padding: 19px 0px 17px;

          i.icon-approved {
            width: 24px;
            height: 24px;
            background: url('/images/streamline-icon-check-square-140-x-140.png') no-repeat center;
            margin-right: 15px;
            background-size: contain;
          }
          i.icon-waiting-approved {
            width: 24px;
            height: 24px;
            background: url('/images/streamline-icon-time-clock-file-search-140-x-140.png') no-repeat center;
            margin-right: 15px;
            background-size: contain;
          }
          i.icon-favorites {
            width: 22px;
            height: 22px;
            background: url('/images/heart.svg') no-repeat center;
            margin-right: 15px;
            background-size: contain;
          }

          &:last-child {
            border-right: 1px solid #ffffff;
          }
        }
      }
    }
  }
`;

export default function ProfileProjects({ contentData }) {
  return (
    <Container>
      {contentData.length > 0 ? (
        <ul className="projects-list">
          {contentData.map((project, index) => (
            <li key={index}>
              <ProjectCard
                id={project.id}
                image={project.gallery}
                name={project.name}
                city={project.city}
                uf={project.uf}
                authorImage={project.user.company_avatar !== null ? project.user.company_avatar : "/images/01.png"}
                authorName={project.user.company_name}
                numberOfLikes={project.likes}
                products={project.products}
              />
            </li>
          ))}
        </ul>
      ) : (
        <h3 className="not-found">Nenhum projeto cadastrado.</h3>
      )}
    </Container>
  )
}
