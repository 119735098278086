import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Header from "../../components/header";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/footer";
import ContainerDefault from "../../components/container";

import ProfileProjects from "./projects";
import ProfileSidebar from "./sidebar";

import api from "../../services/api";

const Container = styled.div`
  .header-bg {
    height: 250px;
    background-color: #9e9e9e;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    color: #fff;

    background-position: center;
    background-size: cover;

    .btn-edit {
      margin-left: auto;
      width: 58px;
      height: 50px;
      margin-bottom: 90px;

      span {
        display: none;
      }

      i {
        border-left: 0;
      }
    }

    @media (min-width: 767px) {
      height: 315px;
      .btn-edit {
        width: 230px;
        margin-bottom: 20px;

        span {
          display: block;
          justify-content: flex-start;
          font-size: 14px;
        }

        i {
          border-left: 1px solid #d99440;
        }
      }
    }

    @media (min-width: 1200px) {
      height: 300px;
    }
  }

  @media (min-width: 767px) {
    padding-bottom: 35px;

    .content-profile {
      display: flex;

      .sidebar-profile {
        max-width: 379px;
        width: 100%;
      }

      .projects-profile {
        flex: 1;
        margin-left: 16px;
      }
    }
  }

  @media (min-width: 1200px) {
    padding-bottom: 85px;
  }
`;

export default function Profile({ pageContext }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function projectsAPI() {
      const response = await api.get(
        `https://apimeuambiente.guararapes.com.br/user/${pageContext.id}/projects/`
      );
      setProjects(response.data);
    }
    projectsAPI();
  }, [pageContext.id]);

  return (
    <>
      <Header />
      <Container>
        <div
          className="header-bg"
          style={
            pageContext.background !== ""
              ? { backgroundImage: `url(${pageContext.background})` }
              : null
          }
        >
          <ContainerDefault />
        </div>

        <ContainerDefault>
          <div className="content-profile">
            <div className="sidebar-profile">
              <ProfileSidebar contentData={pageContext} />
            </div>
            <div className="projects-profile">
              <ProfileProjects contentData={projects} />
            </div>
          </div>
        </ContainerDefault>
      </Container>

      <Newsletter />
      <Footer />
    </>
  );
}
